import { render, staticRenderFns } from "./NovaTransfer.vue?vue&type=template&id=ee76ddfa&scoped=true&"
import script from "./NovaTransfer.vue?vue&type=script&lang=ts&"
export * from "./NovaTransfer.vue?vue&type=script&lang=ts&"
import style0 from "./NovaTransfer.vue?vue&type=style&index=0&id=ee76ddfa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ee76ddfa",
  null
  
)

export default component.exports